body {
  margin: 0;
  font-family: Roboto, sans-serif;
}

html, body, #root, #root > div {
  height: 100%;
}

main {
  height: calc(100% - 86px);
}
